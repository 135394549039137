<template>
  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-5 col-md-6">
        <img src="/images/company/about2.png" class="img-fluid" alt="" />
      </div>
      <div class="col-lg-7 col-md-6 mt-4 mt-lg-0 pt- pt-lg-0">
        <div class="ml-lg-5">
          <div class="section-title mb-3">
            <span class="badge badge-pill badge-soft-primary">Meet the future</span>
            <h4 class="title my-3">Introducing a AIG DEMO</h4>
            <p class="text-muted para-desc">
              Start plan with
              <span class="text-primary font-weight-bold">AIG</span>
              lorem ipsum dolor sit amet, consectetur adipisicing elit. Commodi, delectus! Mollitia, officia? Culpa
              quisquam quia, harum.
            </p>
            <p class="text-muted para-desc mb-0">
              Amet consectetur adipisicing elit. Quos nam laudantium sint aspernatur id. Quam quo, fugit dolores
              labore perspiciatis dolorum amet consequatur eaque laboriosam ducimus ad nisi, nulla ullam.
            </p>
          </div>
          <ul class="list-unstyled text-muted">
            <li class="mb-0">
              <span class="text-primary h5 mr-2"><i class="uil uil-check-circle align-middle"></i></span>Check 1
            </li>
            <li class="mb-0">
              <span class="text-primary h5 mr-2"><i class="uil uil-check-circle align-middle"></i></span>Check 2
            </li>
            <li class="mb-0">
              <span class="text-primary h5 mr-2"><i class="uil uil-check-circle align-middle"></i></span>Check 3
            </li>
          </ul>
          <div class="watch-video mt-3">
            <a href="#" target="_blank" class="btn btn-primary m-1">Read More
              <chevron-right-icon class="fea icon-sm"></chevron-right-icon></a>
            <a href="javascript: void(0);" v-b-modal.modal
              class="btn btn-icon btn-pills video-play-icon btn-primary m-1">
              <video-icon class="icons"></video-icon>
            </a><span class="font-weight-bold text-uppercase small align-middle ml-1">Watch Now</span>
            <b-modal id="modal" hide-footer size="lg" header-close-variant="white" header-class="border-0"
              content-class="border-0" centered>
              <youtube video-id="0vrdgDdPApQ" ref="youtube" width="750" height="450" :player-vars="playerVars">
              </youtube>
            </b-modal>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { ChevronRightIcon, VideoIcon, } from "vue-feather-icons";
  export default {
    components: {
      ChevronRightIcon,
      VideoIcon,
    },
    data() {
      return {
        playerVars: null,
      };
    },
  }
</script>